export default [
  {
    path: '/',
    component: ()=>import('./components/layout.vue'),
    children:[
      {
        path:':version?',
        props: true,
        component: ()=>import('./components/base.vue'),
      }
    ]
  }
]