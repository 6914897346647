import VueRouter from 'vue-router'
import Vue from 'vue'
import routes from '@/routes.js'

Vue.use(VueRouter)
// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location){
//   return originalPush.call(this, location).catch(err=>err)
// }


const router = new VueRouter({
    
    routes // (缩写) 相当于 routes: routes
})

export default router;